import React, { MouseEventHandler } from "react";

import Button from "./Button";

interface props {
  handleSearchClick?: MouseEventHandler<HTMLButtonElement>;
}
const SearchBtn:React.FC<props> = ({
  handleSearchClick
}) => {
  return (
    <Button
      title={"Search"}
      type="button"
      handleClick={handleSearchClick}
      className="searchBtn"
    />
  );
};

export default SearchBtn;

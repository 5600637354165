import React, { ReactNode } from 'react';
import { DropDownOption } from '../InterfaceTypes';
import Button from '../../atoms/Button';
import Header from "../../layouts/Header";
import BackLink from "../../atoms/BackLink";
import SelectWithSearch from "../../atoms/SelectWithSearch";
import LabelField from '../../atoms/LabelField';
interface CreateRelationProps {
    dropDownOptions1: DropDownOption[];
    dropDownOptions2: DropDownOption[];
    dropDownOption_1_title: string;
    dropDownOption_2_title: string;
    children: ReactNode,
    headerTitle: string,

}
const CreateRelation: React.FC<CreateRelationProps> = ({
    dropDownOptions1,
    dropDownOptions2,
    dropDownOption_1_title,
    dropDownOption_2_title,
    children,
    headerTitle

}) => {
    const handleSearchChange = () => {

    }
    return (
        <>
            <div >
                <Header
                    headerName={headerTitle}
                    headerClassName="myAccountCardTitle"
                />
                <div className='managepagesBg mb-5'>
                    <div className="col-lg-3 mb-3">
                        <LabelField title={dropDownOption_1_title} />
                        <div>
                            <SelectWithSearch
                                options={dropDownOptions1}
                                search={false}
                                value={0}
                                onChange={handleSearchChange}
                                className='form-control'
                            />

                        </div>
                    </div>
                    <div className="col-lg-3 mb-3">
                        <LabelField title={dropDownOption_2_title} />
                        <SelectWithSearch
                            options={dropDownOptions1}
                            search={false}
                            value={0}
                            onChange={handleSearchChange}
                        />
                    </div>
                    {children}
                </div>
            </div>
        </>
    )
}

export default CreateRelation;
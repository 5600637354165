import exp from "constants";
export const SERVICE_URL = process.env.REACT_APP_SERVICE_REGISTRY;
export const getRefreshToken = SERVICE_URL + 'get-refresh-token';
export const forgotPassword = "forgot-password";
export const FILE_UPLOAD = "api/file/upload";
export const FILE_DELETE = "api/delete/file";
export const FILE_DOWNLOAD = "api/download/file";
export const LOCALE_TARGETS = 'locale-targets';
export const SAVE_TRANSLATION = 'saveTranslation';
export const UPDATE_TRANSLATIONS = 'updateTranslations';
export const GET_APP_LINKED_LANGS = 'getApplicationsLinkedLanguages';

// ***File url based on server ***
const app_env = process.env.REACT_APP_NODE_ENV;
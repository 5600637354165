import React, { useEffect, useState } from "react";
//import { ApiCall } from "../../../services/ApiServices";
import Header  from "../../layouts/Header";
import Pagination from "../../atoms/Pagination";
import { Link } from "react-router-dom";
import {
  MYMMO_MOBILE_APP,
  MYMMO_WEB,
  MAX_FILE_SIZE,
  MYMMO_SERVICE_MICROSERVICE,

} from "../../../Constants";
// import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import CSVReader from "../../molecules/CSVReader";
import { LabelWithInputField } from "../../molecules/LabelWithInputField";
import { ApiCall } from "../../../services/ApiServices";
import {
  GET_APP_LINKED_LANGS
} from "../../../routes/ApiEndpoints";
// import { useSelector } from "react-redux";

import Icon from "../../../Icons/Icon";
import ResetBtn from "../../atoms/ResetBtn";
import SearchBtn from "../../atoms/SearchBtn";
import SelectWithSearch from "../../atoms/SelectWithSearch";
import InputTextfield from "../../atoms/InputTextField";
import BackLink from "../../atoms/BackLink";
import OverFlowWrapper from "../../atoms/OverFlowWrapper";
import { LanguageData,SearchProps,AllLinkedData,Translation,DropDownOption,Item ,Language} from '../InterfaceTypes';
import secureLocalStorage from "react-secure-storage";


const key = 0;

interface ManageTranslationsTableProps {
  languageLinkedData: LanguageData;
  allLinkedData:AllLinkedData;
  setLanguageLinkedData: React.Dispatch<React.SetStateAction<LanguageData>>;
  handleEditClick: (key: any, type: string) => void;
  getLocalTarget: () => void;
  updateTranslations: (langKey: string, missingTrans: Translation[]) => Promise<void>;
  language: string;
  searchData: SearchProps;
  setSearchData: React.Dispatch<React.SetStateAction<SearchProps>>;
  setLanguage: (langKey: string) => void;
}

const ManageTranslationsTable: React.FC<ManageTranslationsTableProps> = ({
  languageLinkedData,
  setLanguageLinkedData,
  handleEditClick,
  getLocalTarget,
  updateTranslations,
  language,
  searchData,
  setSearchData,
  setLanguage,
  allLinkedData
}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const [errorMsg, setErrorMsg] = useState("");
  const [companyDropDown, setCompanyDropDown] = useState<DropDownOption[]>([]);
  const [languageDropDown,setLanguageDropDown] = useState<DropDownOption[]>([]);
  // const [isFilter, setIsFilter] = useState<boolean>(true); // Example boolean state


  localStorage.translationStrings =
    localStorage.translationStrings || JSON.stringify([]);


useEffect(()=>{
  getApplInkedLangs();
},[])

const getApplInkedLangs = async() => {
  const requestData = {
    appName: searchData.appName,
    langKey: 'en',
  };
  const response = await ApiCall.service(
  GET_APP_LINKED_LANGS
  ,
    "POST",
    requestData,
    true,
    MYMMO_SERVICE_MICROSERVICE
  );
  if(response.status === 200) {
    const {data} = response
      // Map over the data to transform it
  // Map over the data to transform it
  const transformedData = data.map((item: Item) => ({
    value: item.id, // id as value
    label: item.name, // name as label
    languages: item.languages.map((lang: Language) => ({
      value: lang.key.toUpperCase(), // language id as value
      label: lang.name // language name as label
    }))
  }));
     setCompanyDropDown(transformedData)
    setLanguageDropDown(transformedData[0].languages);
  }
 
}
console.log(companyDropDown,languageDropDown,language,searchData)
  /**
   * Translation update in languageData
   * @param translationKey
   * @returns
   */
  const handleInputChange =
    (translationKey: any) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;

      setLanguageLinkedData((prevLanguageLinkedData) => {
        const newLanguageData = {
          ...prevLanguageLinkedData,
          [language]: {
            ...prevLanguageLinkedData[language],
            [translationKey]: {
              ...prevLanguageLinkedData[language][translationKey],
              translation: newValue,
            },
          },
        };

        return newLanguageData;
      });
    };

  /**
   * Page handler
   * @param pageNumber
   */
  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  let itemsPerPage = 5;
  // Get the current items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const entriesArray =
    languageLinkedData[language] &&
    Object.values(languageLinkedData[language]).sort((a, b) =>
      a.text.localeCompare(b.text, undefined, { sensitivity: "base" })
    );
  const currentItems =
  languageLinkedData[language] &&
    entriesArray.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages =
  languageLinkedData[language] &&
    Math.ceil(Object.keys(languageLinkedData[language]).length / itemsPerPage);

  let index = 0;

  const importFromCSV = async (importedData: any, file: any) => {
    if (!importedData || importedData.length === 0) {
      setErrorMsg("Please select a CSV file to upload.");
      return;
    }

    if (file.type !== "text/csv") {
      setErrorMsg(
        "Invalid file format, pleases upload only .csv file format"
      );
      return;
    }

    if (file.size > MAX_FILE_SIZE) {
      setErrorMsg("File size is greater than 10MB.");
      return;
    }
    const importedHeaders = importedData.data[0];

    if (!Object.keys(languageLinkedData).includes(importedHeaders[2])) {
      setErrorMsg("Imported CSV headers do not match the expected format");
   
      return;
    }

    const missingDeveloperStrings: Translation[] = [];
    // const langHeaders: string[] =[];
 
    importedData.data.slice(1).forEach((row: any) => {
      const text = row[1];
        console.log(text);
      if (!text) {
        setErrorMsg("Empty developer string found in imported data.");
        return;
      }
     
      // const matchingLanguage = Object.keys(languageLinkedData).find((language) =>
      //   Object.values(languageLinkedData[importedHeaders[2]]).some(
      //     (translation) => translation.text === text
      //   )
      // );
      
      // if (!matchingLanguage) {
      //   missingDeveloperStrings.push(text);
      //   return; // Skip further processing for this row
      // }

      let id = 0;
     
      if (row.length > 1) {
        for (const data in languageLinkedData[importedHeaders[2]]) {
          if (
            data &&
            languageLinkedData[importedHeaders[2]][data].text === text.toString()
          ) {
            id = languageLinkedData[importedHeaders[2]][data].id;
          }
        }

        const translation: Translation = {
          id: id,
          translation: row[index + 2],
          text,
          isEditing: false,
        };
        
        if (
          languageLinkedData &&
          languageLinkedData[importedHeaders[2]] &&
          languageLinkedData[importedHeaders[2]][translation.id]
        ) {
          languageLinkedData[importedHeaders[2]][translation.id] = translation;

          const updatedLanguageData = {
            ...languageLinkedData,
            [importedHeaders[2]]: {
              ...languageLinkedData[importedHeaders[2]],
              [translation.id]: translation,
            },
          };

          // Update the state with the new data
          setLanguageLinkedData(updatedLanguageData);
        }else {
          missingDeveloperStrings.push(translation);
        }
      }
    });
  console.log(missingDeveloperStrings);
    setErrorMsg("");
   updateTranslations(importedHeaders[2],missingDeveloperStrings);
   
  };

  const getLocaleStrings = async () => {
    const regex = new RegExp(searchData.trans, 'i');

    // Deep clone to avoid mutating original data
    let intialCopy: AllLinkedData = structuredClone(allLinkedData);
    
    // Assuming you're working with the 'EN' language
    const language = 'EN';  // or dynamically determine the language
    
    // Now filter the data within the 'EN' language
    const filteredData = Object.values(intialCopy[language]).filter((item) => 
      regex.test(item.text)  // Matching 'text' property with regex
    );
    
    // After filtering, map back the data to an object format
    let filteredObject: any = {};
    filteredData.forEach((item) => {
      filteredObject[item.id] = { ...item };  // Clone each item
    });
    
    // Create the updated language data with filtered results
    const updatedLanguageData = {
      ...languageLinkedData,  // Retain other languages if any
      [language]: {           // Update only the specific language
        ...filteredObject,
           // Use the filtered data
      },
    };
    
    // Now update the state with the filtered data
    setLanguageLinkedData(updatedLanguageData);
    
    // Debug: print out the updated data
    console.log(updatedLanguageData);

   
   
   
  };

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault();
    await getLocaleStrings();
  };

  const handleReset = async (e: React.FormEvent) => {
    setSearchData({
      trans: "",
      appName: MYMMO_MOBILE_APP,
    });
    setCurrentPage(1);
    getLocalTarget();
  };

  const handleSearchChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSearchData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSelectChange = (selectedOption: { value: string }) => {
    setSearchData((prevData) => ({
      ...prevData, // Spread existing searchData
      appName: selectedOption.value, // Update appName with selected option
    }));
  };
  const handleSelectLang = (selectedOption: { value: string }) => {
    secureLocalStorage.setItem('site_lang',selectedOption.value);
    setLanguage(selectedOption.value);
  }

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  return (
    <>
      {/* <AccessControl
        requiredPermissions={[
          {
            permission: "Translation",
            read: true,
          },
        ]}
        renderNoAccess={true}
      > */}
      <div>
      <Header
          headerName={"Manage Translations"}
          headerClassName="myAccountCardTitle"
        />

        <div className="search-bar pb-3">
          <div className="row">
            <BackLink backLink="/" />
          </div>
          <div className="row">
            <div className="col-8">
              <LabelWithInputField
                label=""
                name="trans"
                handleChange={handleSearchChange}
                placeholder={"Search by developer strings"}
                value={searchData.trans}
                labelClassName="mb-2 colorPrimary poppins-medium"
              />
            </div>
            <div className="col-4">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={handleReset} />
                <SearchBtn handleSearchClick={handleSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="managepagesBg mb-5">
          <div className="row">
            <div className="col-md-12">
              <CSVReader
                key={key}
                importFromCSV={importFromCSV}
                errorMsg={errorMsg}
              />
            </div>
          </div>
          <div className="row mb-3">
            {
              <div className="col-lg-3 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                <SelectWithSearch
                  options={companyDropDown}
                  search={false}
                  value={companyDropDown.find(
                    (option) => option.label === searchData.appName
                  )}
                  onChange={handleSelectChange}
                />

                {/* <select
                  value={language}
                  onChange={handleApplicationChange}
                  className="border-0 form-select rounded-3 px-3 py-1 field-shadow select-field"
                >
                  {languageDropDown &&
                    languageDropDown
                      .sort((a, b) => a?.name.localeCompare(b.name)) // Sort the languages array by name
                      .map((language) => (
                        <option key={language.key} value={language.key}>
                          {language.name}
                        </option>
                      ))}
                </select> */}
              </div>
              //  <AccessControl
              //   requiredPermissions={[
              //     {
              //       permission: "Translation",
              //       update: true,
              //       create: true,
              //     },
              //   ]}
              //   actions={true}
              //   strict={false}
              // >
              //   {currentItems && currentItems.length > 0 && (
              //     <ExportCSV languageData={languageData} language={language} />
              //   )}
              // </AccessControl>
            }
            <div className="col-6 ms-auto text-end">
            <SelectWithSearch
                  options={languageDropDown}
                  search={false}
                  value={languageDropDown.find(
                    (option) => option.value === language
                  )}
                  onChange={handleSelectLang}
                />
             {/* <Link to="/translation/link-app-language">
                <div className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3">
                  {"Link language"}
                </div>
              </Link>*/}
            </div>
          </div>

          <OverFlowWrapper className="table-responsive manageTranslationWrapper">
            <table className="table customTableProperties">
              <thead>
                <tr className="TableHeader">
                  <th style={{ width: "42%" }}>{"Developer strings"}</th>
                  <th style={{ width: "42%" }}>{"Translations"}</th>
                  <th style={{ width: "15%" }}>{"Action"}</th>
                  {/* <AccessControl
                        requiredPermissions={[
                          {
                            permission: "Translation",
                            update: true,
                          },
                        ]}
                      >
                        <th className="ps-4">{t("Action")}</th>
                      </AccessControl> */}
                </tr>
                <tr>
                  <td
                    className="emptyRow p-0"
                    style={{ height: "0.7vw" }}
                    colSpan={3}
                  ></td>
                </tr>
              </thead>
              <tbody>
                {currentItems && currentItems.length > 0 ? (
                  Object.values(currentItems).map((data, key) => (
                    <tr
                      key={key}
                      className="border-bottom box-shadow mb-3 align-middle"
                    >
                      <td
                        className="text-break"
                        data-label={"Developer String"}
                      >
                        {data.text}
                      </td>
                      <td className="text-break" data-label={"Translation"}>
                        <InputTextfield
                          value={data.translation}
                          isDisabled={!data.isEditing}
                          handleChange={handleInputChange(data.id)}
                        />
                      </td>
                      {/* <AccessControl
                            requiredPermissions={[
                              {
                                permission: "Translation",
                                update: true,
                              },
                            ]}
                          > */}
                      <td>
                        <div>
                          {data.isEditing ? (
                            <>
                              <span
                                title={"Save"}
                                onClick={() => handleEditClick(data.id, "save")}
                                className="me-2 secondaryColorHoverEffect"
                              >
                                <Icon {...IconProps} name="SaveIcon" />
                              </span>
                              <span
                                title={"Cancel"}
                                onClick={() =>
                                  handleEditClick(data.id, "cancel")
                                }
                                className="secondaryColorHoverEffect"
                              >
                                <Icon
                                  isIcon={true}
                                  width="0.9vw"
                                  height="0.9vw"
                                  name="close"
                                />
                              </span>
                            </>
                          ) : (
                            <span
                              title={"Edit"}
                              onClick={() => handleEditClick(data.id, "edit")}
                              className="secondaryColorHoverEffect"
                            >
                              <Icon {...IconProps} name="EditIcon" />
                            </span>
                          )}
                        </div>
                      </td>
                      {/* </AccessControl> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="border text-danger text-center">
                      {"No records"}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </OverFlowWrapper>
          {totalPages > 1 && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </div>
          )}
        </div>
      </div>
     
    </>
  );
};

export default ManageTranslationsTable;

//import { GenerateRefreshToken } from "./RefreshTokenService";
import EncryptDecryptService from "./EncryptDecryptService";
//import store, { RootState } from "../store";
import secureLocalStorage from "react-secure-storage";
/*
 *Call to the API
 *@param urlendpoint=urlendpoint of the API
 *@param httpmethod=METHOD
 *@param data=data to the API
 *@returns response from the API
 */

type Headers = {
	"Accept"?: string;
	"Content-Type"?: string;
	Authorization?: any;
	AuthToken?: string;
	//   Access-Control-Allow-Origin": string
};



async function service(
	endpoint: string = "",
	method: string = "POST",
	dataObj: any = "",
	loadingParam: boolean = false,
	microService: string = "central-data-management",

) {
	try {
		
		const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
		console.log(externalElement)
		if (externalElement && loadingParam) {
			externalElement.style.display = "flex";
		}
       

		const appLangKey = secureLocalStorage.getItem('site_lang') ?? 'en';

		dataObj = { ...dataObj, appLangKey: appLangKey }
		let encryptedData = {data:EncryptDecryptService.encryptData(dataObj) , method: 'POST'};
		const url = `${process.env.REACT_APP_SERVICE_REGISTRY_BACKEND_URL}service/${microService}/${endpoint}`;
		let response = await fetch(url, {
			method: 'POST',
			headers: {
			  'Content-Type': 'application/json',

			},
			body: JSON.stringify(encryptedData), // Convert the object to a JSON string
		  });
		
		  let responseData = await response.json();
		  // Await the response and parse it as JSON
		  let decrypteddata: any = EncryptDecryptService.decryptData(responseData);
		  if (externalElement && loadingParam) {
			 externalElement.style.display = "none";
		  }
		  return decrypteddata;
		
	} catch (error) {
		console.log(error);
	}
}

async function fileSendService(
	endpoint: string = "",
	method: string = "POST",
	dataObj: any = "",
	loadingParam: boolean = false,
	microService: string = "central-data-management",

) {
	try {
		const externalElement = document.getElementById('overlay') as HTMLDivElement | null;
		const user = JSON.parse(JSON.parse(localStorage.getItem('persist:root') ?? '{}')?.auth ?? '{}');

		const authToken = JSON.parse(EncryptDecryptService.decryptData(user))?.token;

		if (externalElement && loadingParam) {
			externalElement.style.display = "flex";
		}


		const headers: Headers = {
			"Content-Type": "multipart/form-data",
			// "Access-Control-Allow-Origin": "*",
			// Authorization: token,
			Authorization: `Bearer ${authToken}`
		};
		const url = `${process.env.SERVICE_REGISTRY_BACKEND_URL}service/${microService}/${endpoint}`;
	
		const response = await fetch(url, {
			method: method,
			mode: "cors",
			cache: "no-cache",
			credentials: "same-origin",
			referrer: "no-referrer",
			redirect: "follow",
			body: JSON.stringify({
				method: "POST",

				data: dataObj,

				// data: EncryptDecryptService.encryptData(JSON.stringify(dataObj)),

			}),
			headers: headers,
		});
		const data = await response.json();
		if (externalElement && loadingParam) {
		  externalElement.style.display = "none";
		}
		return data;

	} catch (error) {
		console.log(error);
	}
}

export const ApiCall = {
	service,
	//getService,
	fileSendService
};

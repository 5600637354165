import React, { useState } from 'react';
import CreateRelation from '../Molecules/CreateRelation';
import { DropDownOption } from '../InterfaceTypes'
import Button from '../../atoms/Button';

import BackLink from "../../atoms/BackLink";
const CreatePersonZone = () => {
    const [personOptions, setPersonOptions] = useState<DropDownOption[]>([]);
    const [zoneOptions, setZoneOptions] = useState<DropDownOption[]>([]);
    return (
        <>

            <CreateRelation
                dropDownOptions1={personOptions}
                dropDownOptions2={zoneOptions}
                headerTitle='Create person zone relation'
                dropDownOption_1_title='Person'
                dropDownOption_2_title='Zone'
            >
                <div className='row mt-5'>
                    <div className='col-3'>
                        <BackLink backLink="/" />
                    </div>

                    <div className="col-3">
                        <Button
                            title={"Save"}
                            className="saveBtn p-2 px-3"

                        />
                    </div>

                </div>
            </CreateRelation>
        </>
    )
}

export default CreatePersonZone;
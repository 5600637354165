import logo from './logo.svg';
import './App.css';
import { Routes } from "./routes/Routes";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingOverlay from "./components/utlis/LoadingOverlay";


function App() {
  return (
    <div className="App">
      <LoadingOverlay display="none" />
      <RouterProvider router={Routes} key={0} />
    </div>
  );
}

export default App;

import { AES, enc } from "crypto-ts";

// Ensure the key is exactly 32 bytes (256 bits) for AES-256
const key = 'H98zM6i/55yNJfkFsbu0HrzlFo17FtR9'; // 32 bytes for AES-256
const iv = key.slice(0, 16); // First 16 bytes for IV (AES uses 128-bit IV)

// Parse key and IV using UTF-8 encoding
const parsedKey = enc.Utf8.parse(key);
const parsedIv = enc.Utf8.parse(iv);

const EncryptDecryptService = {
    encryptData: (data: any): string => {
        try {
            // Convert object to JSON string if the data is not already a string
            const dataToEncrypt = typeof data === 'string' ? data : JSON.stringify(data);

            // Encrypt the JSON string
            const ciphertext = AES.encrypt(dataToEncrypt, parsedKey, { iv: parsedIv }).toString();
            return ciphertext;
        } catch (error) {
            console.error("Encryption error:", error); // Log the encryption error
            return '';
        }
    },

    decryptData: (encryptedData: string): any => {
        try {
            // Decrypt the data
            const decrypted = AES.decrypt(encryptedData, parsedKey, { iv: parsedIv });
            const decryptedData = decrypted.toString(enc.Utf8);

            // Parse the decrypted data back to an object if it's in JSON format
            return JSON.parse(decryptedData);
        } catch (error) {
            console.error("Decryption error:", error); // Log the decryption error
            return '';
        }
    }
}

export default EncryptDecryptService;

import React, { useState, useEffect } from 'react'
import ManageTranslationsTable from '../Molecules/ManageRelationTable';
import {SearchData,TableData,initialData} from '../InterfaceTypes'
const Index = () => {

const [search,setSearchData] = useState<SearchData>({
    entityType1: 0,
    entity1: '',
    entityType2: 0,
    entity2: '',
    relation: 0,
    relationStatus: '',
});
const [data, setData] = useState<TableData[]>(initialData);
    return (
        <>
        <ManageTranslationsTable
           data          = {data}
           search        = {search}
           setSearchData = {setSearchData}
         />

        </>
    )
}

export default Index;
// Tile.tsx
import React from 'react';


interface TileProps {
  text: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
}

const Tile: React.FC<TileProps> = ({ text, width = "250px", height = "250px", backgroundColor = "#f0f0f0" }) => {
  return (
    <div className="tile" style={{
      backgroundColor: '#f5f6f7',
      padding: '3rem 1rem',
      position: 'relative',
      textAlign: 'center',
    }}>
      <span className="tile-text">{text}</span>
    </div>
  );
};

export default Tile
import React, { MouseEventHandler } from "react";
import Icon from "../../Icons/Icon";

import Button from "./Button";

interface props {
  handleResetClick?: MouseEventHandler<HTMLButtonElement>;

}
const ResetBtn:React.FC<props> = ({
  handleResetClick
}) => {
  return (
    <Button
      title=""
      type="reset"
      handleClick={handleResetClick}
      className="resetBtn me-3"
    >
      <span title={"Reset"}>
        <Icon isIcon={true} width="1vw" height="1vw" name="refreshIcon" />
      </span>
    </Button>
  );
};

export default ResetBtn;

import { Outlet } from "react-router-dom";
import React from "react";

import Sidebar from "./Sidebar";

export const RootLayout = () => {

return (
    <div style={{ display: "flex" }}>
    <Sidebar />
    <div
      style={{ marginLeft: "9.8vw", width: "88.3vw", marginRight: "1.8vw" }}
      className="container-fluid"
    >
      <Outlet /> {/* Renders the matched child route components */}
    </div>
  </div>
)
}

export default RootLayout;


export interface TableData {
    entityType1: string;
    entity1: string;
    entityType2: string;
    entity2: string;
    relation: string;
    relationStatus: string;
    validFrom: string;
    isDeleted: boolean;
    createdDate: string;
  }
  export interface SearchData {
    entityType1: number;
    entity1: string;
    entityType2: number;
    entity2: string;
    relation: number;
    relationStatus: string;
  }

  export type DataProps = {
    data: TableData[];
  };;

  export const initialData: TableData[] = [
    {
      entityType1: "Person",
      entity1: "Ravi",
      entityType2: "Zone",
      entity2: "Borsbeeksebrug 1",
      relation: "Admin",
      relationStatus: "Approved",
      validFrom: "10-09-2024",
      isDeleted: false,
      createdDate: "10-09-2024",
    },
    {
      entityType1: "Property",
      entity1: "Appartement 12",
      entityType2: "Zone",
      entity2: "Waterstraat 8",
      relation: "Member",
      relationStatus: "Approved",
      validFrom: "10-09-2024",
      isDeleted: false,
      createdDate: "10-09-2024",
    },
    {
      entityType1: "Property",
      entity1: "Appartement 12",
      entityType2: "Zone",
      entity2: "Waterstraat 8",
      relation: "Member",
      relationStatus: "Approved",
      validFrom: "10-09-2024",
      isDeleted: false,
      createdDate: "10-09-2024",
    },
    {
      entityType1: "Person",
      entity1: "Ravi",
      entityType2: "Property",
      entity2: "Appartement 12",
      relation: "Owner",
      relationStatus: "Approved",
      validFrom: "10-09-2024",
      isDeleted: false,
      createdDate: "10-09-2024",
    },
    {
      entityType1: "Person",
      entity1: "Ravi",
      entityType2: "Property",
      entity2: "Appartement 12",
      relation: "Resident",
      relationStatus: "Approved",
      validFrom: "10-09-2024",
      isDeleted: true,
      createdDate: "10-09-2024",
    },
    {
      entityType1: "Person",
      entity1: "Ravi",
      entityType2: "Property",
      entity2: "Appartement 12",
      relation: "Resident",
      relationStatus: "Approved",
      validFrom: "10-09-2024",
      isDeleted: true,
      createdDate: "10-09-2024",
    }
    
  ];
import React from "react";
import { createBrowserRouter } from "react-router-dom";
import * as ROUTE from "../routes/RouteConstants";
import NewRootLayout from '../components/layouts/RootLayout';
import ManageTranslations from '../components/Translations/Organism/ManageTranslations';
import DashBoard from '../components/DashBoard/Index';
import ManageRelations from '../components/ManageRelations/Organism/Index';
import CreatePersonZoneRelation from '../components/CreateRelations/Organisms/CreatePersonZone';
import CreatePersonProperty from "../components/CreateRelations/Organisms/CreatePersonProperty";
import CreatePropertyZone from "../components/CreateRelations/Organisms/CreatePropertyZone";
export const Routes = createBrowserRouter([

    {
        path: "/",
        element: <NewRootLayout />,
        children: [
            { path: '/', element: <DashBoard /> },
            { path: '/manage-translations', element: <ManageTranslations /> },
            { path: '/manage-relations', element: <ManageRelations /> },
            {path:'/create-person-zone-relation',element:<CreatePersonZoneRelation/>},
            {path:'/create-person-property-relation',element:<CreatePersonProperty/>},
            {path:'/create-property-zone-relation',element:<CreatePropertyZone/>},
            { path: '/dashboard', element: <DashBoard /> },
        ]
    }
]);
import React, { useState } from 'react';
import { TableData, SearchData } from '../InterfaceTypes'
import Header from "../../layouts/Header";
import Pagination from "../../atoms/Pagination";
import ResetBtn from "../../atoms/ResetBtn";
import SearchBtn from "../../atoms/SearchBtn";
import SelectWithSearch from "../../atoms/SelectWithSearch";
import BackLink from "../../atoms/BackLink";
import Button from '../../atoms/Button';
import OverFlowWrapper from "../../atoms/OverFlowWrapper";
import { LabelWithInputField } from "../../molecules/LabelWithInputField";
import Icon from "../../../Icons/Icon";

interface ManageRelationTableProps {
    data: TableData[]
    search: SearchData;
    setSearchData: React.Dispatch<React.SetStateAction<SearchData>>;
}
const ManageRelationTable: React.FC<ManageRelationTableProps> = ({
    data,
    search,
    setSearchData
}) => {
    const [currentPage, setCurrentPage] = useState(1);
    /**
     * Page handler
     * @param pageNumber
     */
    const handleSearch = async (e: React.FormEvent) => {
        e.preventDefault();

    };

    const handleReset = async (e: React.FormEvent) => {

        setCurrentPage(1);

    };
    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    const handleSearchChange = () => {

    }

   
    
      let itemsPerPage = 5;
      // Get the current items to display on the current page
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    
      const entriesArray = data;
       
      const currentItems =
        data &&
        entriesArray.slice(indexOfFirstItem, indexOfLastItem);
    console.log(currentItems,indexOfFirstItem, indexOfLastItem)
      // Calculate the total number of pages
      const totalPages =
        data &&
        Math.ceil(Object.keys(data).length / itemsPerPage);
    
      let index = 0;
    

    console.log(data, search)
    const IconProps = {
        isIcon: true,
        width: "1vw",
        height: "1vw",
    };
    return (
        <>
            <div>
                <Header
                    headerName={"Manage relations"}
                    headerClassName="myAccountCardTitle"
                />
                <div className='row'>
                    <BackLink backLink="/" />
                </div>
                <div className="search-bar pb-4">
                    <div className="d-flex gap-2">
                        <div className="w-15 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                            <SelectWithSearch
                                options={[]}
                                search={false}
                                value={0}
                                onChange={handleSearchChange}
                                 placeHolder='Entity type 1'
                            />
                        </div>
                        <div className="w-10 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                            <LabelWithInputField
                                label=""
                                name="trans"
                                handleChange={handleSearchChange}
                                placeholder={"Entity 1"}
                                value={''}
                                labelClassName="mb-2 colorPrimary poppins-medium"
                            />
                        </div>
                        <div className="w-15 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                            <SelectWithSearch
                                options={[]}
                                search={false}
                                value={0}
                                onChange={handleSearchChange}
                                placeHolder='Entity type 2'
                            />
                        </div>
                       
                        <div className="w-10 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                            <LabelWithInputField
                                label=""
                                name="trans"
                                handleChange={handleSearchChange}
                                placeholder={"Entity 2"}
                                value={''}
                                labelClassName="mb-2 colorPrimary poppins-medium"
                            />
                        </div>
                        <div className="w-15 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                            <SelectWithSearch
                                options={[]}
                                search={false}
                                value={0}
                                onChange={handleSearchChange}
                                placeHolder='Relation '
                            />
                        </div>
                        <div className="w-15 translation-language-dropdown mb-3 mb-lg-0 multiSelectWrapper">
                            <LabelWithInputField
                                label=""
                                name="trans"
                                handleChange={handleSearchChange}
                                placeholder={"Relation status"}
                                value={''}
                                labelClassName="mb-2 colorPrimary poppins-medium"
                            />
                        </div>
                        <div className="w-15 d-flex align-items-center">
                            <ResetBtn handleResetClick={handleReset} />
                            <SearchBtn handleSearchClick={handleSearch} />
                        </div>
                    </div>
                </div>
                <div className="managepagesBg mb-5">
                    <OverFlowWrapper className="table-responsive manageTranslationWrapper">
                        <table className="table customTableProperties">
                            <thead>
                                <tr className="TableHeader">
                                    <th >{"Entity type 1"}</th>
                                    <th >{"Entity 1"}</th>
                                    <th >{"Entity type 2"}</th>
                                    <th >{"Entity 2 "}</th>
                                    <th >{"Relation"}</th>
                                    <th >{"Relation status"}</th>
                                    <th >{"Valid from"}</th>
                                    <th >{"Is deleted?"}</th>
                                    <th >{"Created date/Updated date"}</th>
                                    <th >{"Action"}</th>

                                </tr>
                                <tr>
                                    <td
                                        className="emptyRow p-0"
                                        style={{ height: "0.7vw" }}
                                        colSpan={3}
                                    ></td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems && currentItems.length > 0 ? (currentItems.map((val, key) => (
                                    <tr>
                                        <td>{val.entity1}</td>
                                        <td>{val.entityType1}</td>
                                        <td>{val.entity2}</td>
                                        <td>{val.entityType2}</td>
                                        <td>{val.relation}</td>
                                        <td>{val.relationStatus}</td>
                                        <td>{val.validFrom}</td>
                                        <td>{val.isDeleted ? 'True' : 'False'}</td>
                                        <td>{val.createdDate}</td>
                                        <td>
                                            <span
                                                title={"Edit"}

                                                className="secondaryColorHoverEffect"
                                            >
                                                <Icon {...IconProps} name="delete" />
                                            </span>
                                        </td>
                                    </tr>
                                ))) : (
                                    <tr>
                                        <td colSpan={5} className="border text-danger text-center">
                                            {"No records"}
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </OverFlowWrapper>
                    {totalPages > 1 && (
                        <div className="pagination justify-content-center mt-4 px-4">
                            <Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={handlePageChange}
                            />
                        </div>
                    )}
                </div >
            </div >
        </>
    )
}

export default ManageRelationTable;
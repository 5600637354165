import React,{useState,useEffect} from 'react'
import ManageTranslationsTable from '../Molecules/ManageTranslationsTable';
import CustomNotify from "../../atoms/CustomNotify";
import secureLocalStorage from "react-secure-storage";
import {Translation,LanguageData,AllLinkedData,Language,SearchProps} from '../InterfaceTypes';
import {
  LOCALE_TARGETS,
  SAVE_TRANSLATION,
  UPDATE_TRANSLATIONS,
} from "../../../routes/ApiEndpoints";
import {
  MYMMO_MOBILE_APP,
  MYMMO_WEB,
  MYMMO_SERVICE_MICROSERVICE,
  MAX_FILE_SIZE,

} from "../../../Constants";
import { ApiCall } from "../../../services/ApiServices";

const  ManageTranslations = () =>{
  const [searchData, setSearchData] = useState<SearchProps>({
    trans: "",
    appName: MYMMO_MOBILE_APP,
  });
  const [allLinkedData,setAllLinkedData] = useState<AllLinkedData>({});
  const [languageLinkedData, setLanguageLinkedData] = useState<LanguageData>({});
  const [languageDropDown, setLanguageDropDown] = useState<Language[]>();
  const [language, setLanguage] = useState("EN");
  const lang_key = secureLocalStorage.getItem("site_lang") ?? "en";
  useEffect(()=>{
    getLocalTargetAPI();
  },[language,searchData.appName])
 
  const getLocalTargetAPI = async () => {
    const requestData = {
      appName: searchData.appName,
      langKey: language.toLocaleLowerCase(),
    };
    const response = await ApiCall.service(
      LOCALE_TARGETS,
      "POST",
      requestData,
      true,
      MYMMO_SERVICE_MICROSERVICE
    );
console.log(response)
    if (response.status === 200) {
      
      const { languages,translations} = response.data;
      const initialLanguageData: LanguageData = {};
      setLanguageDropDown(languages);
      setLanguage(Object.keys(translations)[0]);
      Object.keys(translations).forEach((languageKey) => {
        initialLanguageData[languageKey] = {};
        Object.keys(translations[languageKey]).forEach(
          (translationKey) => {
            initialLanguageData[languageKey][translationKey] = {
              ...translations[languageKey][translationKey],
              isEditing: false, // Initialize the isEditing state
            };
          }
        );
      });
      setAllLinkedData(initialLanguageData);
      setLanguageLinkedData(initialLanguageData);
    }
  };

  const handleEditClick = async (key: any, type: string) => {
    if (type === "save") {
      if (languageLinkedData[language][key].translation === "") {
        
        CustomNotify({
          type: "warning",
          message: "Please provide translation",
        });
        return;
      }
      const response = await ApiCall.service(
        SAVE_TRANSLATION,
        "POST",
        {
          translation: languageLinkedData[language][key].translation,
          key: key,
          appName:MYMMO_MOBILE_APP ,
        },
        true,
        MYMMO_SERVICE_MICROSERVICE
      );

      if (response.status === 200) {
        CustomNotify({
          type: "success",
          message: response.message || response.msg,
        });
      } else {
        CustomNotify({
          type: "error",
          message: response.message || response.msg,
        });
      }
    }
    setLanguageLinkedData((prevLanguageLinkedData) => {
      const newLanguageData = {
        ...prevLanguageLinkedData,
        [language]: {
          ...prevLanguageLinkedData[language],
          [key]: {
            ...prevLanguageLinkedData[language][key],
            isEditing: !prevLanguageLinkedData[language][key].isEditing,
          },
        },
      };

      return newLanguageData;
    });
   };
   const updateTranslations = async (langKey: string, missingTrans: Translation[]): Promise<void> => {
    let response = await ApiCall.service(
      UPDATE_TRANSLATIONS,
      "POST",
      {
        translations: languageLinkedData[langKey],
        newTranslations:missingTrans,
        appName:MYMMO_MOBILE_APP ,
      },
      true,
      MYMMO_SERVICE_MICROSERVICE
    );
    if (response.status === 200) {
      setLanguage("EN");
      CustomNotify({
        type: "success",
        message: response.message || response.msg,
      });
     // setKey((prevKey) => prevKey + 1); // Update the key to force re-render
    }
   }
  return (
    <>
    <ManageTranslationsTable 
      languageLinkedData         = {languageLinkedData}
      setLanguageLinkedData      = {setLanguageLinkedData}
      handleEditClick            = {handleEditClick}
      getLocalTarget             = {getLocalTargetAPI}
      updateTranslations         = {updateTranslations}
      language                   = {language}
      searchData                 = {searchData}
      setSearchData              = {setSearchData}
      setLanguage                = {setLanguage}
      allLinkedData              = {allLinkedData} 
      
    />
    </>
  )
}

export default ManageTranslations;
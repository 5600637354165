import React, { useEffect } from 'react'
import Tile from '../atoms/Tile';
import { useNavigate } from "react-router-dom";
import Header from "../layouts/Header";
interface TileProps {
  name: string;
  title: string;
  path: string;

}
const tileItms: TileProps[] = [
  { name: "dashboard", title: "Manage Translations", path: "/manage-translations" },
  { name: "dashboard", title: "Manage relations", path: "/manage-relations" },
  { name: "dashboard", title: "Create person zone relation", path: "/create-person-zone-relation" },
  { name: "dashboard", title: "Create property zone relation", path: "/create-property-zone-relation" },
  { name: "dashboard", title: "Create person property relation", path: "/create-person-property-relation" },
];
function Index() {
  const navigate = useNavigate();
  const handlickTitle = (path: string) => {
    navigate(path || "/default-path");
  }
  useEffect(() => {
    navigate('/dashboard')
  }, [])
  return (
    <>
      <div>

        <Header
          headerName={"Manage Dashboard"}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-3">
          <div className="" style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, 1fr)',
            gridGap: '1rem',
            gap: '1rem',
          }}>
            {tileItms.map(item => (
              <div onClick={() => handlickTitle(item.path)}>

                <Tile text={item.title} />
              </div>
            ))}

          </div>
        </div>
      </div>
    </>
  )
}

export default Index
import React, { ReactNode } from "react";
import Icon from "../../Icons/Icon";

interface Props {
  title: ReactNode;
  className?: string;
  disabled?: boolean;
  handleClick?: () => void;
  type?: "button" | "submit" | "reset" | undefined;
  iconName?:string;
}

const PaginationButton: React.FC<Props> = ({
  title,
  className,
  disabled = false,
  handleClick,
  type = "button",
  iconName
}) => {
  return (
    <>
      <button
        type={type}
        disabled={disabled}
        className={className}
        onClick={handleClick}
      >
        <Icon name={iconName}  isIcon={true} width="1vw" height="1vw" fill="#fff"/>
        {title}
      </button>
    </>
  );
};

export default PaginationButton;
